import Chart from 'chart.js/auto';

document.addEventListener('turbo:load', () => {
  if (!document.getElementById('chart_symptom')) return;

  const bgColors = Array(2).fill([
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
  ]).flat();
  const borderColors = Array(2).fill([
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
  ]).flat();

  ['symptom', 'seriousness', 'stage', 'cause'].forEach((type) => {
    const ctx = document.getElementById(`chart_${type}`).getContext('2d');
    const data = JSON.parse(document.getElementById(`data_${type}`).value);

    let bgClrs = bgColors;
    bgClrs.splice(data[1].findIndex(element => element.startsWith('なし')), 1, 'rgba(206, 206, 206, 0.2)');
    let bdClrs = borderColors;
    bdClrs.splice(data[1].findIndex(element => element.startsWith('なし')), 1, 'rgba(206, 206, 206, 1)');
    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: data[1],
        datasets: [{
          data: data[2],
          backgroundColor: bgClrs,
          borderColor: bdClrs,
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: data[0]
          }
        }
      }
    });
  });
});
