import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  openModal = () => {
    document.getElementById('work_modal').classList.remove('hidden');
  };

  closeModal = () => {
    console.log('close modal');
    document.getElementById('work_modal').classList.add('hidden');
  };

  toggleSubpart(event) {
    let row;
    if (event.target.closest('tr')) {
      row = event.target.closest('tr');
    } else {
      row = event.target.closest('li');
    }
    const level = row.children[0].children[0].dataset.level;
    let nxt = row.nextSibling;
    let nextLevel = nxt.children[0].children[0].dataset.level;
    if (nextLevel <= level) return;

    if (nxt.classList.contains('hidden')) {
      event.target.previousElementSibling.classList.add('hidden');
    } else {
      event.target.previousElementSibling.classList.remove('hidden');
    }
    while (nxt) {
      nextLevel = nxt.children[0].children[0].dataset.level;
      if (nextLevel <= level) break;

      if (nxt.classList.contains('hidden')) {
        nxt.classList.remove('hidden');
      } else {
        nxt.classList.add('hidden');
      }
      nxt = nxt.nextSibling;
    }
  }
}